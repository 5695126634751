import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";

export const NetlifyApiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  endpoints: (build) => ({
    getDeployState: build.query({
      query: () => ({
        url: previewWidget.DEPLOYMENT_STATUS_API,
      }),
    }),
  }),
  reducerPath: "netlify",
});

export const {
  useGetDeployStateQuery,
} = NetlifyApiSlice;
