import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import jwtDecode from "jwt-decode";

const axiosBaseQuery = async (args) => {
  const {
    url,
    method = "get",
    data = {},
    params = {},
    headers = {},
  } = args;

  try {
    const result = await axios({
      data,
      headers: {
        "X-DKMSIO-Client": process.env.GATSBY_IO_CLIENT_ID,
        ...headers,
      },
      method,
      params,
      url,
      withCredentials: true,
    });

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError;

    return {
      error: {
        data: err.response?.data || err.message,
        status: err.response?.status,
      },
    };
  }
};

const getCsrfToken = async (force = false) => {
  const oldCsrfToken = sessionStorage.getItem("ioCsrfToken");

  if (!force && oldCsrfToken !== null) {
    const oldTCsrfokenPayload = jwtDecode(oldCsrfToken);

    if (oldTCsrfokenPayload.exp > (Math.floor(Date.now() / 1000) + 100)) {
      return oldCsrfToken;
    }
  }

  const csrfTokenResult = await axiosBaseQuery({
    method: "post",
    url: `${process.env.GATSBY_IO_HOST}/token`,
  });

  if (csrfTokenResult?.data?.access_token) {
    sessionStorage.setItem("ioCsrfToken", csrfTokenResult.data.access_token);
  }

  return csrfTokenResult.data.access_token;
};

const ioBaseQuery = async (
  args,
  api,
  extraOptions,
  forceRenewToken = false,
) => {
  if (args.apiRequirements.includes("session")) {
    await axiosBaseQuery({
      method: "post",
      url: `${process.env.GATSBY_IO_HOST}/session`,
    });
  }

  const csrfToken = args.apiRequirements.includes("csrfToken")
    ? await getCsrfToken(forceRenewToken)
    : null;

  let result = await axiosBaseQuery(
    {
      ...args,
      ...(csrfToken !== null && { headers: { "X-CSRF-Token": csrfToken } }),
    },
    api,
    extraOptions,
  );

  if (result.error) {
    switch (result.error.status) {
      case 401:
        break;

      case 403:
        // only retry once
        if (!forceRenewToken && args.apiRequirements.includes("csrfToken")) {
          result = ioBaseQuery(
            args,
            api,
            extraOptions,
            true,
          );
        }
        break;

      default:
    }
  }

  return result;
};

export default createApi({
  baseQuery: ioBaseQuery,
  endpoints: () => ({}),
  keepUnusedDataFor: 300,
  reducerPath: "io",
  tagTypes: ["SSP"],
});
