import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentArticle: null,
  filterCriterionsPoster: {
    criterionCategory: [],
    criterionYear: [],
  },
  filterCriterionsPublication: {
    criterionCategory: [],
    criterionJournal: [],
    criterionYear: [],
  },
  filterCriterionsStudy: [],
  filterTextPoster: "",
  filterTextPublication: "",
  filterTextStudy: "",
  targetIndex: 0,
  targetItem: null,
};

const ArticleSlice = createSlice({
  initialState,
  name: "article",
  reducers: {
    changeCurrentArticle: (state, { payload }) => {
      state.currentArticle = payload;
    },
    setFilterCriterions: (state, { payload }) => {
      switch (payload.key) {
        case "publication":
          state.filterCriterionsPublication = payload.value;
          break;
        case "study":
          state.filterCriterionsStudy = payload.value;
          break;
        case "poster":
          state.filterCriterionsPoster = payload.value;
          break;
        default:
      }
    },
    setFilterText: (state, { payload }) => {
      switch (payload.key) {
        case "publication":
          state.filterTextPublication = payload.value;
          break;
        case "study":
          state.filterTextStudy = payload.value;
          break;
        case "poster":
          state.filterTextPoster = payload.value;
          break;
        default:
      }
    },
    setTargetItem: (state, { payload }) => {
      state.targetItem = payload.item;
      state.targetIndex = payload.index;
    },
  },
});

export const {
  changeCurrentArticle,
  setFilterCriterions,
  setFilterText,
  setTargetItem,
} = ArticleSlice.actions;

export default ArticleSlice.reducer;
