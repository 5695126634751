import React from "react";

import PiwikPro, { PageViews } from "@piwikpro/react-piwik-pro";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import PreviewStatusWidget from "./src/components/previewStatusWidget/previewStatusWidget";
import Store from "./src/state/store";

import "./src/styles/style.scss";

let lastTitle = "";

export const onRouteUpdate = ({ location }) => {
  if (process.env.GATSBY_PIWIK_SITE_ID) {
    // document.title workaround stolen from:
    // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
    const sendPageView = () => {
      const { title } = document;

      if (lastTitle !== title) {
        lastTitle = title;
        PageViews.trackPageView(location);
      }
    };

    // Minimum delay for reactHelmet's requestAnimationFrame
    const delay = Math.max(500, 0);
    setTimeout(sendPageView, delay);
  }

  return null;
};

export const wrapRootElement = ({ element }) => {
  const { store, persistor } = Store();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {() => element}
      </PersistGate>
    </Provider>
  );
};

export const wrapPageElement = ({ element }) => (
  <>
    {element}

    {typeof previewWidget !== "undefined" && previewWidget.PREVIEW && previewWidget.SITE_ID && (
      <PreviewStatusWidget />
    )}
  </>
);

export const onClientEntry = () => {
  if (process.env.GATSBY_PIWIK_SITE_ID) {
    PiwikPro.initialize(process.env.GATSBY_PIWIK_SITE_ID, "https://dkms.containers.piwik.pro");
  }
};
