import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import ArticleSlice from "../features/articleSlice";
import ComplianceConsentSlice from "../features/complianceConsentSlice";
import IoBaseApiSlice from "../features/io/ioBaseApiSlice";
import { NetlifyApiSlice } from "../features/netlifyApiSlice";

export default () => {
  const store = configureStore({
    middleware: (getDefaultMiddleware) => (
      // see: https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
      // see: https://github.com/reduxjs/redux-toolkit/issues/415
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat([
        IoBaseApiSlice.middleware,
        NetlifyApiSlice.middleware,
      ])
    ),
    reducer: {
      [IoBaseApiSlice.reducerPath]: IoBaseApiSlice.reducer,
      [NetlifyApiSlice.reducerPath]: NetlifyApiSlice.reducer,
      article: persistReducer({
        key: "medweb-t",
        storage: storageSession,
      }, ArticleSlice),
      complianceConsent: ComplianceConsentSlice,
    },
  });

  const persistor = persistStore(store);

  return { persistor, store };
};
